import {History, LocationState} from 'history'
import {NavigatorForMemberPage} from './NavigatorForMemberPage'
import {MemberInfoForCompletePage, MemberInfoForConfirmPage, MemberInfoForInputPage} from '../Props/MemberRegisterPageLocationStates'
import {MemberEditAddressInfo} from '../Props/MembersEditAddressPageLocationStates'
import {Signboard} from '../DTO/SignboardDTO'
import {MemberJoinInfo, MemberJoinInfoForInputPage} from '../Props/MembersJoinPageStates'
import {Optional} from '../../Common/TypeHelper'
import {BucketInfo} from '../DTO/BucketInfoDTO'
import {MembersLeaveBucketPageLocationState} from '../Props/MembersLeaveBucketPageLocationStates'
import {ContractInfoWithContractDate, ContractInputInfo, CreditCardError, UsingMyCreditCardInfo} from '../Props/ContractInputInfo'
import {PaymentMethod} from '../DTO/PaymentMethodDTO'
import {ContractInfo} from '../DTO/MemberContractInfoDTO'
import {MemberVerifyIdentityInfo} from '../DTO/MemberVerifyIdentityInfoDTO'
import {MobileCarrierStartPaymentInfo} from '../DTO/MobileCarrierStartPaymentInfoDTO'
import {MembersRegisteredPageCvsLocationState, MembersRegisteredPageFreeBucketLocationState, MembersRegisteredPageLocationState} from '../View/Pages/Signup/MembersRegisteredPage'
import {CvsContract} from '../DTO/CvsContractDTO'
import {ShippingAddress, ShippingAddressInfo} from '../DTO/MemberShippingAddressDTO'
import {BucketMemberId} from '../DTO/BucketMemberId'
import {EnrollmentReasonSelectedInfo} from '../View/Components/InputContentBlocks/EnrollmentReasonSelectContentBlock'
import {SignedUpSignboard} from '../DTO/SignedUpSignboardDTO'
import {BucketWithMailMagazineInfo} from '../View/Pages/EditMailMagazine/MembersEditMailMagazineInputPage'
import {MemberJoinInfoForCompletePage} from '../View/Pages/Join/MembersJoinCompletePage'

export class NavigatorForMemberPageImpl implements NavigatorForMemberPage {

  private readonly browserHistory: History
  private readonly baseUrl: string

  constructor(browserHistory: History<LocationState>) {
    this.browserHistory = browserHistory
    this.baseUrl = process.env.REACT_APP_MEMBER_CLIENT_ROOT!
  }

  goToMembersInvitationPage(): void {
    this.browserHistory.push('/invitation')
  }

  goToMembersSignupInputPage(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo?: ContractInputInfo,
    replaceHistory?: boolean): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    }
    if (replaceHistory) {
      this.browserHistory.replace(params)
    } else {
      this.browserHistory.push(params)
    }
  }

  changeMembersSignupInputPageState(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo?: ContractInputInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    })
  }

  backToMembersSignupInputPageCauseCreditCardError(
    creditCardError: CreditCardError,
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        creditCardError,
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToMembersSignupInputPageCauseMobileCarrierError(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        isMobileCarrierError: true,
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToMembersSignupInputPageCauseUserForbiddenError(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        isUserForbiddenError: true,
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  goToMembersSignupInputConfirmPage(
    memberRegisterInformationProps: MemberInfoForConfirmPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo): void {
    replaceAndPush(
      this.browserHistory,
      `/${signboard.signupKey}/signup/input`,
      `/${signboard.signupKey}/signup/confirm`,
      {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
      },
    )
  }

  goToMembersRegisteredPage(
    signupKey: string,
    state: MembersRegisteredPageLocationState |
      MembersRegisteredPageCvsLocationState |
      MembersRegisteredPageFreeBucketLocationState,
  ): void {
    this.browserHistory.push({pathname: `/${signupKey}/signup/registered`, state})
  }

  goToMembersRegisteredPageForCreditCard(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    contractInfoForCompletePage: ContractInfoWithContractDate,
    returnPageUrl: Optional<string>,
  ): void {
    const state: MembersRegisteredPageLocationState = {
      contractMethod: 'credit_card',
      memberInfo,
      signboard,
      bucketMemberIdList,
      contractInfoForCompletePage,
      returnPageUrl,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForMobileCarrier(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    contractInfoForCompletePage: ContractInfoWithContractDate,
    returnPageUrl: Optional<string>,
  ): void {
    const state: MembersRegisteredPageLocationState = {
      contractMethod: 'mobile_carrier',
      memberInfo,
      signboard,
      bucketMemberIdList,
      contractInfoForCompletePage,
      returnPageUrl,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForConvenienceStore(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    cvsContract: CvsContract,
    contractInfoForCompletePage: ContractInfoWithContractDate,
    returnPageUrl: Optional<string>,
  ): void {
    const state: MembersRegisteredPageCvsLocationState = {
      contractMethod: 'convenience_store',
      memberInfo,
      signboard,
      cvsContract,
      contractInfoForCompletePage,
      bucketMemberIdList: cvsContract.bucketMemberIdList,
      returnPageUrl,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForFreeBucket(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    returnPageUrl: Optional<string>,
  ): void {
    const state: MembersRegisteredPageFreeBucketLocationState = {
      contractMethod: 'none',
      memberInfo,
      signboard,
      bucketMemberIdList,
      returnPageUrl,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersSignupMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/signup/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToMembersInvitationCompletePage(signboard: Signboard): void {
    this.browserHistory.push({
      pathname: `/${signboard.signupKey}/invitation/complete`,
      state: {
        signboard: signboard,
      },
    })
  }

  goToAccountTopPage(): void {
    this.browserHistory.push('/account')
  }

  goToAccountInformationPage(): void {
    this.browserHistory.push('/account/information')
  }

  goToEditMailPage(): void {
    this.browserHistory.push('/account/information/mail/update_input')
  }

  goToEditPasswordPage(): void {
    this.browserHistory.push('/account/information/password/update_input')
  }

  goToEditPasswordCompletePage(): void {
    this.browserHistory.push('/account/information/password/update_complete')
  }

  goToEditMailInvitationCompletePage(): void {
    this.browserHistory.push('/account/information/mail/send_complete')
  }

  goToEditMailUpdatedPage(): void {
    this.browserHistory.replace('/account/information/mail/update_complete')
  }

  goToEditAddressInputPage(): void {
    this.browserHistory.push('/account/information/address/update')
  }

  goToEditAddressConfirmPage(memberEditAddressInfo: MemberEditAddressInfo): void {
    replaceAndPush(
      this.browserHistory,
      '/account/information/address/update',
      '/account/information/address/update_confirm',
      memberEditAddressInfo,
    )
  }

  goToEditAddressCompletePage(): void {
    this.browserHistory.push('/account/information/address/update_complete')
  }

  goToMembersReAgreePage(sourcePath: string): void {
    const sourceUrl = encodeURIComponent(this.baseUrl.slice(0, -1) + sourcePath)
    this.browserHistory.push(`/account/agree?source=${sourceUrl}`)
  }

  goToEditBucketMailMagazineInfoPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/mail_magazine`)
  }

  goToEditBucketMailMagazineInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/mail_magazine/update`)
  }

  goToEditBucketMailMagazineConfirmPage(
    bucketKey: string,
    bucketInfo: BucketInfo,
    subscribeMailMagazineIdList: number[])
    : void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketKey}/mail_magazine/update`,
      `/${bucketKey}/mail_magazine/update_confirm`,
      {bucketInfo, subscribeMailMagazineIdList},
    )
  }

  goToEditBucketMailMagazineCompletePage(bucketKey: string, bucketInfo: BucketInfo): void {
    this.browserHistory.push(
      {
        pathname: `/${bucketKey}/mail_magazine/update_complete`,
        state: bucketInfo,
      },
    )
  }

  goToEditBucketNicknameInfoPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/nickname`)
  }

  goToEditBucketNicknameInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/nickname/update`)
  }

  goToEditBucketNicknameConfirmPage(
    bucketKey: string,
    bucketInfo: BucketInfo,
    nickname: string,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketKey}/nickname/update`,
      `/${bucketKey}/nickname/update_confirm`,
      {bucketInfo, nickname},
    )
  }

  goToEditBucketNicknameCompletePage(bucketKey: string, bucketInfo: BucketInfo): void {
    this.browserHistory.push(
      {
        pathname: `/${bucketKey}/nickname/update_complete`,
        state: bucketInfo,
      },
    )
  }

  goToEditMailMagazineInputPage(
    subscribeMailMagazineIdList?: number[],
  ): void {
    this.browserHistory.push(
      '/account/information/mail_magazine/update',
      {subscribeMailMagazineIdList},
    )
  }

  goToEditMailMagazineConfirmPage(
    bucketWithMailMagazineInfos: BucketWithMailMagazineInfo[],
    subscribeMailMagazineIdList: number[],
  ): void {
    replaceAndPush(
      this.browserHistory,
      '/account/information/mail_magazine/update',
      '/account/information/mail_magazine/update_confirm',
      {
        bucketWithMailMagazineInfos,
        subscribeMailMagazineIdList,
      },
    )
  }

  goToEditMailMagazineCompletePage(): void {
    this.browserHistory.push(
      {
        pathname: '/account/information/mail_magazine/update_complete',
      },
    )
  }

  goToEditContractInfoPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/contract`)
  }

  goToEditContractInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/contract/update`)
  }

  changeEditContractInputPageState(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
      },
    })
  }

  backToEditContractInputPageCauseCreditCardError(
    creditCardError: CreditCardError,
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
    nextContractInfo: ContractInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/contract/update`,
      state: {
        creditCardError,
        bucketInfo: bucketInfo,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
        nextContractInfo: nextContractInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToEditContractInputPageCause3DSAuthError(
    creditCardError: CreditCardError,
    bucketInfo: BucketInfo,
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/contract/update`,
      state: {
        creditCardError: creditCardError,
        bucketInfo: bucketInfo,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToEditContractInputPageCauseMobileCarrierError(
    bucketInfo: BucketInfo,
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/contract/update`,
      state: {
        isMobileCarrierError: true,
        bucketInfo: bucketInfo,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  goToEditContractConfirmPage(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
    nowContractInfo: ContractInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketInfo.key}/contract/update`,
      `/${bucketInfo.key}/contract/update_confirm`,
      {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
        nowContractInfo,
      },
    )
  }

  goToEditContractMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/contract/update/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToEditContractCompletePage(
    bucketInfo: BucketInfo,
    nextContractStartDate?: string,
  ): void {
    this.browserHistory.push({
      pathname: `/${bucketInfo.key}/contract/update_complete`,
      state: {bucketInfo: bucketInfo, nextContractStartDate: nextContractStartDate},
    })
  }

  goToJoinInputPage(
    memberJoinInfo: Optional<MemberJoinInfoForInputPage>,
    signboard: Optional<SignedUpSignboard>,
    paymentMethodList?: PaymentMethod[],
    myCreditCardInfoList?: UsingMyCreditCardInfo[],
    contractInputInfo?: ContractInputInfo): void {
    this.browserHistory.push({
      pathname: `/${signboard?.signupKey}/join/input`,
      state: {
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    })
  }

  changeJoinInputPageState(param: {
    memberJoinInfo: Optional<MemberJoinInfoForInputPage>,
    signboard: Optional<SignedUpSignboard>,
    paymentMethodList?: PaymentMethod[],
    myCreditCardInfoList?: UsingMyCreditCardInfo[],
    contractInputInfo?: ContractInputInfo,
    returnPageUrl?: string,
  }): void {
    this.browserHistory.replace({
      state: {
        memberInfo: param.memberJoinInfo,
        signboard: param.signboard,
        paymentMethodList: param.paymentMethodList,
        myCreditCardInfoList: param.myCreditCardInfoList,
        contractInputInfo: param.contractInputInfo,
      },
    })
  }

  backToMembersJoinInputPageCauseCreditCardError(
    creditCardError: CreditCardError,
    memberJoinInfo: MemberJoinInfoForInputPage,
    signboard: SignedUpSignboard,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/join/input`,
      state: {
        creditCardError,
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToMembersJoinInputPageCause3DSAuthError(
    param: {
      creditCardError: CreditCardError,
      signupKey: string,
      contractInputInfo: ContractInputInfo,
      agreedIdList: MemberJoinInfo['agreedIdList'],
      mailMagazineIdCheckedList: MemberJoinInfo['mailMagazineIdCheckedList'],
      enrollmentReason: Optional<EnrollmentReasonSelectedInfo>,
      returnPageUrl?: string,
    },
  ): void {
    this.browserHistory.push({
      pathname: `/${param.signupKey}/join/input`,
      state: {
        creditCardError: param.creditCardError,
        contractInputInfo: param.contractInputInfo,
        agreedIdList: param.agreedIdList,
        mailMagazineIdCheckedList: param.mailMagazineIdCheckedList,
        enrollmentReasonSelectedInfo: param.enrollmentReason,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    })
  }

  backToMembersJoinInputPageCauseMobileCarrierError(
    param: {
      signupKey: string,
      contractInputInfo: ContractInputInfo,
      agreedIdList: MemberJoinInfo['agreedIdList'],
      mailMagazineIdCheckedList: MemberJoinInfo['mailMagazineIdCheckedList'],
      enrollmentReason: Optional<EnrollmentReasonSelectedInfo>,
      returnPageUrl?: string,
    },
  ): void {
    this.browserHistory.push({
      pathname: `/${param.signupKey}/join/input`,
      state: {
        isMobileCarrierError: true,
        contractInputInfo: param.contractInputInfo,
        agreedIdList: param.agreedIdList,
        mailMagazineIdCheckedList: param.mailMagazineIdCheckedList,
        enrollmentReasonSelectedInfo: param.enrollmentReason,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    })
  }

  goToJoinConfirmPage(
    param: {
      memberJoinInfo: MemberJoinInfo,
      signboard: SignedUpSignboard,
      paymentMethodList: PaymentMethod[],
      myCreditCardInfoList: UsingMyCreditCardInfo[],
      contractInputInfo: ContractInputInfo,
      returnPageUrl?: string,
    },
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${param.signboard.signupKey}/join/input`,
      `/${param.signboard.signupKey}/join/confirm`,
      {
        memberInfo: param.memberJoinInfo,
        signboard: param.signboard,
        paymentMethodList: param.paymentMethodList,
        myCreditCardInfoList: param.myCreditCardInfoList,
        contractInputInfo: param.contractInputInfo,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    )
  }

  goToJoinCompletePage(
    param: {
      memberJoinInfo: MemberJoinInfoForCompletePage,
      signboard: Signboard,
      bucketMemberIdList: BucketMemberId[],
      contractInfoForCompletePage: ContractInfoWithContractDate,
      returnPageUrl: Optional<string>,
    },
  ): void {
    this.browserHistory.push({
      pathname: `/${param.signboard.signupKey}/join/complete`,
      state: {
        contractMethod: param.contractInfoForCompletePage.method,
        memberInfo: param.memberJoinInfo,
        signboard: param.signboard,
        bucketMemberIdList: param.bucketMemberIdList,
        contractInfoForCompletePage: param.contractInfoForCompletePage,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    })
  }

  goToJoinCompletePageForConvenienceStore(
    param: {
      memberJoinInfo: MemberJoinInfoForCompletePage,
      signboard: Signboard,
      contractInfoForCompletePage: ContractInfoWithContractDate,
      cvsContract: CvsContract,
      returnPageUrl: Optional<string>,
    },
  ): void {
    this.browserHistory.push({
      pathname: `/${param.signboard.signupKey}/join/complete`,
      state: {
        contractMethod: 'convenience_store',
        memberInfo: param.memberJoinInfo,
        signboard: param.signboard,
        contractInfoForCompletePage: param.contractInfoForCompletePage,
        cvsContract: param.cvsContract,
        bucketMemberIdList: param.cvsContract.bucketMemberIdList,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    })
  }

  goToJoinCompletePageForFreeBucket(
    param: {
      memberJoinInfo: MemberJoinInfoForCompletePage,
      signboard: Signboard,
      bucketMemberIdList: BucketMemberId[],
      returnPageUrl: Optional<string>,
    },
  ): void {
    this.browserHistory.push({
      pathname: `/${param.signboard.signupKey}/join/complete`,
      state: {
        contractMethod: 'none',
        memberInfo: param.memberJoinInfo,
        signboard: param.signboard,
        bucketMemberIdList: param.bucketMemberIdList,
        ...((param.returnPageUrl != undefined) ? {returnPageUrl: param.returnPageUrl} : {}),
      },
    })
  }

  goToMembersJoinMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/join/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToMembersLeaveBucketConfirmPage(
    membersLeaveBucketPageLocationState: MembersLeaveBucketPageLocationState,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave`,
      `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave_confirm`,
      membersLeaveBucketPageLocationState,
    )
  }

  goToMembersLeaveBucketCompletePage(
    membersLeaveBucketPageLocationState: MembersLeaveBucketPageLocationState,
  ): void {
    this.browserHistory.push({
      pathname: `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave_complete`,
      state: membersLeaveBucketPageLocationState,
    })
  }

  // MS-3008 ST
  goToCancelBucketLeaveInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/leave_cancel/input`)
  }

  changeCancelBucketLeaveInputPageState(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
      },
    })
  }

  backToCancelBucketLeaveInputPageCauseCreditCardError(
    creditCardError: CreditCardError,
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
    nextContractInfo: ContractInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/leave_cancel/input`,
      state: {
        creditCardError,
        bucketInfo: bucketInfo,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
        nextContractInfo: nextContractInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToCancelBucketLeaveInputPageCause3DSAuthError(
    creditCardError: CreditCardError,
    bucketInfo: BucketInfo,
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/leave_cancel/input`,
      state: {
        creditCardError: creditCardError,
        bucketInfo: bucketInfo,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToCancelBucketLeaveInputPageCauseMobileCarrierError(
    bucketInfo: BucketInfo,
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/leave_cancel/input`,
      state: {
        isMobileCarrierError: true,
        bucketInfo: bucketInfo,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  goToCancelBucketLeaveConfirmPage(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
    nowContractInfo: ContractInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketInfo.key}/leave_cancel/input`,
      `/${bucketInfo.key}/leave_cancel/confirm`,
      {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
        nowContractInfo,
      },
    )
  }

  goToCancelBucketLeaveMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/leave_cancel/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToCancelBucketLeaveCompletePage(
    bucketInfo: BucketInfo,
    nextContractStartDate?: string,
  ): void {
    this.browserHistory.push({
      pathname: `/${bucketInfo.key}/leave_cancel/complete`,
      state: {bucketInfo: bucketInfo, nextContractStartDate: nextContractStartDate},
    })
  }

  // MS-3008 ED

  goToMembersVerifiedIdentityPage(): void {
    this.browserHistory.push('/password/reset/verified_identity')
  }

  goToResetPasswordInputPage(
    oneTimeToken: string,
    memberInfo: MemberVerifyIdentityInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/password/reset/input',
      state: {
        oneTimeToken,
        memberInfo,
      },
    })
  }

  goToResetPasswordCompletePage(): void {
    this.browserHistory.push('/password/reset/complete')
  }

  goToShippingAddressInputPage(): void {
    this.browserHistory.push('/account/information/shipping_address/input')
  }

  goToShippingAddressInputConfirmPage(shippingAddress: ShippingAddress): void {
    replaceAndPush(
      this.browserHistory,
      '/account/information/shipping_address/input',
      '/account/information/shipping_address/input_confirm',
      shippingAddress,
    )
  }

  goToShippingAddressInputCompletePage(): void {
    this.browserHistory.push('/account/information/shipping_address/input_complete')
  }

  goToEditShippingAddressInputPage(shippingAddressId: number): void {
    this.browserHistory.push(`/account/information/shipping_address/${shippingAddressId}/update`)
  }

  goToEditShippingAddressConfirmPage(
    shippingAddressInfo: ShippingAddressInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/account/information/shipping_address/${shippingAddressInfo.shippingAddressId}/update`,
      `/account/information/shipping_address/${shippingAddressInfo.shippingAddressId}/update_confirm`,
      shippingAddressInfo,
    )
  }

  goToEditShippingAddressCompletePage(shippingAddressId: number): void {
    this.browserHistory.push(`/account/information/shipping_address/${shippingAddressId}/update_complete`)
  }

  goToAccountErasureInputPage(): void {
    this.browserHistory.push(`/account/erasure`)
  }

  goToAccountErasureCompletePage(): void {
    this.browserHistory.push(`/account/erasure/complete`)
  }

  goToMembersInvalidOneTimeUrlPage(): void {
    this.browserHistory.replace('/invalidOneTimeUrl')
  }

  goToMembersInvalidInvitationIdPage(): void {
    this.browserHistory.replace('/invalidInvitationId')
  }

  goToMembersInvalidUrlPage(): void {
    this.browserHistory.replace('/invalidUrl')
  }

  goToMembersServerErrorPage(): void {
    this.browserHistory.push('/serverError')
  }

  goToMembersInvalidOperationPage(): void {
    this.browserHistory.push('/invalidOperation')
  }

  goToMembersDuplicatedRegisterPage(): void {
    this.browserHistory.push('/duplicatedRegister')
  }

  goBack(): void {
    this.browserHistory.goBack()
    window.scrollTo(0, 0)
  }

  goToUrl(path?: string): void {
    if (!path) {
      this.goToAccountTopPage()
    } else {
      location.href = path
    }
  }

  generateLogoutPageUrlWithRedirectUrl(to?: string): string {
    return new URL('logout' + (to ? `?to=${to}` : ''), this.baseUrl).href
  }
}

const replaceAndPush = (
  browserHistory: History<LocationState>,
  currentPathName: string,
  nextPathName: string,
  state: Record<string, unknown>,
): void => {
  browserHistory.replace({
    pathname: currentPathName,
    state: state,
  })
  browserHistory.push({
    pathname: nextPathName,
    state: state,
  })
}
